import { useEffect, useState } from "react";
import * as signalR from "@microsoft/signalr";

const retryTimes = [0, 200];

const startInitializeAsync = (socketConnection, coords) => {
	const pushId = !localStorage.getItem("coordsMob")
		? ""
		: "" + localStorage.getItem("pushToken") + "";
	socketConnection.invoke("initializeAsync", JSON.stringify(coords), pushId).then();
};

const startConnection = async (socketConnection, coords) => {
	await socketConnection
		.start()
		.then(() => startInitializeAsync(socketConnection, coords))
		.catch((error) => console.log("startConnection Error:", error));
};

const useSignalR = (url, coords) => {
	const [connection, setConnection] = useState(null);

	useEffect(() => {
		if (!coords || !coords.lat || !coords.lon) {
			return;
		}

		const newConnection = new signalR.HubConnectionBuilder()
			.withUrl(url, {
				accessTokenFactory: () => localStorage.getItem("accessToken"),
			})
			.withAutomaticReconnect({
				nextRetryDelayInMilliseconds: (context) => {
					const index =
						context.previousRetryCount < retryTimes.length
							? context.previousRetryCount
							: retryTimes.length - 1;
					return retryTimes[index];
				},
			})
			.configureLogging(signalR.LogLevel.Trace)
			.build();

		async function startNewConnection() {
			try {
				await newConnection.start();
				console.log("Socket -> connected");
				startInitializeAsync(newConnection, coords);
			} catch (error) {
				console.log("startConnection Error:", error);
			}
		}

		newConnection.onreconnected(async () => {
			console.log("Socket -> reconnected");
			startInitializeAsync(newConnection, coords);
		});

		newConnection.onclose((error) => {
			console.log("error in onclose:", error);
		});

		setConnection(newConnection);

		startNewConnection();

		return () => {
			if (newConnection && newConnection.state !== signalR.HubConnectionState.Disconnected) {
				newConnection.stop().catch(console.error);
			}
		};
	}, [coords, url]);

	return connection;
};

export default useSignalR;
