import { GET_CLIENT_INVOICES } from './invoices.actions'

const initialState = {
  clientInvoices: []
}

function invoices(state = initialState, action) {
  switch (action.type) {
    case GET_CLIENT_INVOICES:
      return { ...state, clientInvoices: action.payload }
    default:
      return { ...state }
  }
}
export default invoices
