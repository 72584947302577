/* eslint-disable no-unused-vars */
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import "react-phone-input-2/lib/bootstrap.css";

import { connect } from "react-redux";
import PhotoBlock from "../PhotoBlock";
import { getUserData, postImage, postUserData, resetUserData } from "../profile.action";
import CountrySelector from "../../CountrySelector";
import { CameraCircleIcon, ImageIcon } from "../../../shared/assets/icons";
import cls from "./editProfile.module.scss";
import { Input } from "../../../shared/ui/Input/Input";
import { t } from "i18next";
import { Button, ButtonVariant } from "../../../shared/ui/Button/button";
import { apiImages } from "../../../config";
import Modal from "../../../shared/ui/Modal/Modal";
import AlertModal from "../../Modals/ui/AlertModal/AlertModal";
import { showModalAlert, showModalAction, showModal } from "../../Modals/store/modal.action";
import ActionModal from "../../Modals/ui/ActionModal/ActionModal";
import Loader from "../../../shared/ui/Loader/Loader";
import { Trans } from "react-i18next";
import { MAX_IMAGE_SIZE_MB } from "../../../config-in-git";
import { compressImage } from "src/shared/helpers/compressImage";
import { imageDefaultQuality } from "src/shared/constants";

const nameDefaultRegex = new RegExp(/^[а-яёА-ЯЁёІіЇїЄєґҐ'-]+$/);
const internationalNameRegex = new RegExp(/^[a-zA-Z\u00C0-\u024F\u0400-\u04FF\u0500-\u052F'-]+$/u);
const chkEmail = new RegExp(/([A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+[.]+[A-Za-z]{2,}$)/);

const EditProfile = memo((props) => {
	const {
		userData,
		getUserData,
		postImage,
		postUserData,
		userImgId,
		resetUserData,
		changeUserData,
		showModalAlert,
		showModalAction,
		userDataLoaded,
		isOpenModalAlert,
		isOpenModalAction,
		countryCode,
	} = props;

	const [firstName, setFirstName] = useState({ name: "", isError: false });
	const [lastName, setLastName] = useState({ name: "", isError: false });
	const [email, setEmail] = useState({ name: "", isError: false });
	const [phone, setPhone] = useState("");
	const [regionCode, setRegionCode] = useState(null);
	const [isSaveEnabled, setIsSaveEnabled] = useState(true);
	const [file, setFile] = useState(null);
	const [titleModal, setTitleModal] = useState("");
	const inputRef = useRef(null);
	const [loadingPhoto, setLoadingPhoto] = useState(false);

	const setInitialUserData = useCallback(() => {
		if (userData?.id) {
			const phone = userData?.phone.slice(4);

			setFirstName({ name: userData?.firstName || "" });
			setLastName({ name: userData?.lastName || "" });
			setEmail({ name: userData?.email || "" });
			setPhone(phone);
			setRegionCode(userData?.regionCode);
		}
	}, [userData]);

	const showMAction = useCallback(() => {
		showModalAction(true);
	}, [showModalAction]);

	const convertToBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onloadend = () => {
				resolve(reader.result.split(",")[1]);
			};
			reader.onerror = reject;
			reader.readAsDataURL(file);
		});
	};

	const photoToBase64 = useCallback(
		async (event, retardation) => {
			try {
				setLoadingPhoto(true);
				const file = event.target.files[0];
				const fileName = event.target.files[0]?.name;
				const reader = new FileReader();
				const type = file.type.split("/")[0];
	
				if (file.size > 15728640 || type !== "image") {
					showModalAlert(true);
					setTitleModal(
						type !== "image" ? (
							t("only_photos_can_uploaded")
						) : (
							<Trans i18nKey="image_exceeded" values={{ maxSize: MAX_IMAGE_SIZE_MB }} />
						),
					);

					// Reset the file state
					event.target.value = "";

					setFile((prevFiles) => ({
						...prevFiles,
						[fileName]: null,
					}));
				} else {
					reader.onloadend = async () => {
						try {
							const compressedFile = await compressImage(file, imageDefaultQuality.width, imageDefaultQuality.height, imageDefaultQuality.quality);
							const base64EncodedImage = await convertToBase64(compressedFile);

							const imgInfo = {
								name: fileName,
								fileArray: base64EncodedImage,
							};
	
							const data = retardation
								? {
									id: userData.id,
									firstName: userData.firstName,
									email: userData.email,
									workAdressId: "",
									homeAdressId: "",
								}
								: false;
	
							postImage(imgInfo, data);
							setLoadingPhoto(false);
						} catch (error) {
							console.error("Error compressing image:", error);
							setLoadingPhoto(false);
						}
					};
				}

				if (file) {
					reader.readAsDataURL(file);
				}
			} catch (e) {
				console.log("Error uploading photo - ", e);
				setLoadingPhoto(false);
			}
		},
		[compressImage, convertToBase64, postImage, showModalAlert, t, userData.email, userData.firstName, userData.id],
	);

	const updateUserData = () => {
		const obj = {
			id: userData.id,
			firstName: !firstName.name ? userData.firstName : firstName.name,
			lastName: !lastName.name ? userData.lastName : lastName.name,
			photo: userImgId === null ? userData.photo : `${apiImages}/download/${userImgId}`,
			email: !email.name ? userData.email : email.name,
			workAddress: userData.workAddress,
			homeAddress: userData.homeAddress,
		};

		postUserData(obj);
	};

	const isNameValid = useCallback(
		(name) => {
			if (!userData?.country || userData.country === "UA") {
				return name.length >= 2 && nameDefaultRegex.test(name);
			} else {
				return name.length >= 2 && internationalNameRegex.test(name);
			}
		},
		[userData?.country],
	);

	const onChangeName = useCallback(
		({ target }) => {
			const name = target.value.replace(/\s+/g, "").trim();
			const typeField = target.name;

			const newName = {
				name,
				isError: !isNameValid(name),
			};

			typeField === "firstName" ? setFirstName(newName) : setLastName(newName);
			setIsSaveEnabled(userData?.firstName !== newName.name ? !isNameValid(target.value) : true);
		},
		[isNameValid, userData?.firstName],
	);

	const isEmailValid = (email) => chkEmail.test(email);
	const onChangeEmail = useCallback(
		({ target }) => {
			const newEmailName = {
				name: target.value,
				isError: !isEmailValid(target.value),
			};

			setEmail(newEmailName);
			setIsSaveEnabled(userData?.email !== newEmailName.name ? !isEmailValid(target.value) : true);
		},
		[userData?.email],
	);

	const uploadPhoto = useCallback(() => {
		if (inputRef?.current) {
			inputRef?.current?.click();
			showModalAction(false);
		}
	}, [showModalAction]);

	const actionDataForModal = useMemo(
		() => [
			{
				label: t("select_from_gallery"),
				icon: <ImageIcon className={cls.modalIcon} />,
				callback: uploadPhoto,
			},
		],
		[uploadPhoto],
	);

	useEffect(() => {
		if (!userData?.id) {
			const countryCode = localStorage.getItem("country_сode");
			getUserData(countryCode);
		}

		setInitialUserData();
		setIsSaveEnabled(!userImgId);
	}, [getUserData, setInitialUserData, userData?.id, userImgId]);

	useEffect(() => {
		if (changeUserData) {
			showModalAlert(true);
			setTitleModal(t("changes_saved"));
		}
	}, [changeUserData, showModalAlert]);

	if (userDataLoaded || loadingPhoto) {
		return <Loader />;
	}

	return (
		<div className={cls.EditProfileWrapper}>
			<Modal isOpen={isOpenModalAlert || isOpenModalAction} isCloseAction>
				{isOpenModalAlert && <AlertModal title={titleModal} />}
				{isOpenModalAction && (
					<ActionModal title={t("upload_photo")} actionData={actionDataForModal} />
				)}
			</Modal>
			<div className={cls.photoBlock} onClick={showMAction}>
				<PhotoBlock photo={userData.photo} className={cls.photo}>
					<CameraCircleIcon className={cls.cameraIcon} />
					<input
						type="file"
						name="driverPhoto"
						ref={inputRef}
						style={{ display: "none" }}
						onChange={photoToBase64}
					/>
				</PhotoBlock>
			</div>
			<div className={cls.profileField}>
				<Input
					className={cls.input}
					type="text"
					value={firstName.name}
					name="firstName"
					onChange={onChangeName}
					placeholder={t("enter_name")}
					isError={firstName.isError}
					valueError={t("incorrect_name")}
				/>
				<Input
					className={cls.input}
					type="text"
					value={lastName.name}
					name="lastName"
					onChange={onChangeName}
					placeholder={t("enter_last_name")}
					isError={lastName.isError}
					valueError={t("incorrect_last_name")}
				/>
				<Input
					className={cls.input}
					type="text"
					value={t(regionCode) || t("сity_undefined")}
					placeholder={t("city")}
					disabled
				/>
				<div className={cls.inputPhone}>
					<CountrySelector
						countryCode={countryCode}
						placeholder={t("enter_phone")}
						type="number"
						maxLength="9"
						value={phone}
						isRequired
						phoneInputProps={{ disableDropdown: true }}
					/>
				</div>
				<Input
					className={cls.input}
					type="text"
					value={email.name}
					onChange={onChangeEmail}
					placeholder="Email"
					isError={email.isError}
					valueError={t("incorrect_email")}
				/>
			</div>
			<div className={cls.saveBtn}>
				<Button
					variant={ButtonVariant.background}
					fullWidth
					onClick={updateUserData}
					disabled={isSaveEnabled}
				>
					{t("save")}
				</Button>
			</div>
		</div>
	);
});

const mapStateToProps = (state) => {
	return {
		userData: state.profile.userData,
		userImgId: state.profile.userImgId,
		userDataLoaded: state.profile.userDataLoaded,
		changeUserData: state.profile.changeUserData,
		isOpenModalAlert: state.modal.isOpenModalAlert,
		isOpenModalAction: state.modal.isOpenModalAction,
		countryCode: state.auth.countryCode,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUserData: (data) => dispatch(getUserData(data)),
		postImage: (data, profileDta) => dispatch(postImage(data, profileDta)),
		postUserData: (data) => dispatch(postUserData(data)),
		resetUserData: () => dispatch(resetUserData()),
		showModal: (status) => dispatch(showModal(status)),
		showModalAlert: (status) => dispatch(showModalAlert(status)),
		showModalAction: (status) => dispatch(showModalAction(status)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
