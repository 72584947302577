import React, { useEffect } from 'react'
import Invoices from 'src/pages/InvoicesPage/Invoices'
import PageHeader from 'src/components/PageHeader'
import PageLayout from 'src/components/PageLayout'
import { t } from 'i18next'
import { getClientInvoices } from './invoices.actions'
import { connect } from 'react-redux'

function InvoicesPage(props) {
  const {
    invoicesData,
    getClientInvoices
  } = props;

  useEffect(() => {
    getClientInvoices();
  }, []);

  return (
    <>
      <PageHeader title={t('invoices')} />
      <PageLayout>
        <Invoices invoicesData={invoicesData} />
      </PageLayout>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    invoicesData: state.invoices.clientInvoices,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getClientInvoices: () => dispatch(getClientInvoices()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesPage)