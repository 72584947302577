import React from 'react'
import { t } from 'i18next'
import styles from './styles.module.scss'
import dayjs from "dayjs"

function Invoices(props) {
  const {
    invoicesData,
  } = props;

  return (
    <div className={styles.invoices}>
      {invoicesData.length > 0 ? (
        invoicesData
          .sort((a, b) => dayjs(b.sentAt).valueOf() - dayjs(a.sentAt).valueOf())
          .map(invoice => (
            <a key={invoice.id} href={invoice.url} download className={styles.invoice}>
              <p>{invoice.sentAt ? dayjs(invoice.sentAt).format("DD MMM YYYY") : t("invoice")}</p>
            </a>
          ))
      ) : (
        <p>{t("no_data_to_view")}</p>
      )}
    </div>
  )
}

export default Invoices;