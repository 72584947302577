import { Suspense, lazy, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { Navigate, Route, Routes, useNavigate, useLocation } from "react-router-dom";

import ErrorComponent from "./components/ErrorComponent";
import ErrorSocket from "./components/ErrorSocket";
import ErrorToken from "./components/ErrorToken";

import OrderPreferences from "./components/OrderPreferences";
import OrderRoute from "./components/OrderRoute";
import SignalRComponent from "./components/SocketConnection";
import ProtectedRoute from "./hoc/ProtectedRoute";
import AuthPage from "./pages/AuthPage";
import AuthPasswordPage from "./pages/AuthPasswordPage";
import AuthenticationPage from "./pages/AuthenticationPage";
import ChatHistoryPage from "./pages/ChatHistoryPage";
import EditPassword from "./pages/EditPassword";
import ForgotPage from "./pages/ForgotPage";
import HelpListPage from "./pages/HelpListPage";
import HistoryPage from "./pages/HistoryPage";
import NewsPage from "./pages/NewsPage";
import OrderDonePage from "./pages/OrderDonePage";
import OrderPage from "./pages/OrderPage";
import PaymentInfoPage from "./pages/PaymentInfoPage";
import ProfilePage from "./pages/ProfilePage";
import QuestionsAccountPage from "./pages/QuestionsAccountPage";
import QuestionsPage from "./pages/QuestionsPage";
import RegistrationPage from "./pages/RegistrationPage";
import SendCodePage from "./pages/SendCodePage";
import InvoicesPage from "./pages/InvoicesPage";

import "./app/styles/index.scss"; // Новые стили для новго дизайна
import ChangeMapSize from "./components/ChangeMapSize/ChangeMapSize";
import OrderComment from "./components/OrderComment";
import "./i18n/config";
import i18n from "./i18n/config";
import { languagesNames } from "./mock/languages";
import ApkPolicyPage from "./pages/ApkPolicy";
import ApkRulesPage from "./pages/ApkRules";
import DeleteAccountPage from "./pages/DeleteAccountPage";
import EditProfilePage from "./pages/EditProfile";
import SettingsPage from "./pages/SettingsPage";
import { onLanguageChangedCB } from "./shared/callbacks/onLanguageChanged";
import { parseLocalStorageItem } from "./shared/helpers/parseLocalStorageItem";
import { useCoordinatesUtils } from "./shared/hooks/useCoordinatesUtils.js";
import useLanguageManager from "./shared/hooks/useLanguageManager";
import Loader from "./shared/ui/Loader/Loader";
import { setTheme } from "./store/reducers/theme-reducer/actions";
import OrderPaymentPage from "./pages/OrderPaymentPage";
import { errorFunctionClose } from "./components/ErrorSocket/error.action";
import { updateOrderStatus } from "./components/SocketConnection/socket.action";
import { onContentLoaded } from "./shared/callbacks";
import { pagesCustomStopPreloader } from "./shared/variables";
import useZendeskLauncherPosition from "./components/ZendeskWidget/hooks/useZendeskLauncherPosition";
import { setLocaleToDayJs } from "./shared/helpers/setLocaleToDayJs";

// Lazy imports
const LazyFavoriteAddressPage = lazy(() => import("./pages/FavoriteAddressPage"));

const App = (props) => {
	const { userData, setTheme, pos, updateOrderStatus } = props;
	const location = useLocation();

	const { changeLanguage } = useLanguageManager();
	const { setStandardCoordinates } = useCoordinatesUtils();

	const navigate = useNavigate();

	const getLanguage = useCallback(() => {
		const languageName = localStorage.getItem("language_name")?.toUpperCase();
		const validLanguages = languagesNames.map((language) => language.locale);

		if (userData.language) {
			localStorage.setItem("language_name", userData.language);
			i18n.changeLanguage(userData.language.toLowerCase());
			onLanguageChangedCB();
			setLocaleToDayJs(userData.language.toLowerCase());

			return;
		}

		if (languageName && validLanguages.includes(languageName)) {
			localStorage.setItem("language_name", languageName);
			changeLanguage(languageName);
		} else {
			localStorage.setItem("language_name", "UK");
		}
	}, [changeLanguage, userData.language]);

	// Update or set the application language
	useEffect(() => {
		const accessToken = localStorage.getItem("accessToken");

		if (userData?.language && accessToken) {
			getLanguage();
		}

		if (!accessToken) {
			setTimeout(getLanguage, 1000);
		}
	}, [getLanguage, userData.language]);

	// Changing the application theme
	useEffect(() => {
		const appTheme = localStorage.getItem("appTheme");

		if (!appTheme) {
			document.querySelector("body").setAttribute("data-theme", "light");
			localStorage.setItem("appTheme", "light");
			setTheme("light");
		} else {
			document.querySelector("body").setAttribute("data-theme", appTheme);
			setTheme(appTheme);
		}
	}, [setTheme]);

	//Sets the default coordinate values
	useEffect(() => {
		const coords = parseLocalStorageItem("coords");

		if (!coords || !Number.isFinite(coords?.latitude) || !Number.isFinite(coords?.longitude)) {
			setStandardCoordinates();
		}
	}, [setStandardCoordinates]);

	// Add indentation for zendesk launcher
	useZendeskLauncherPosition();

	useEffect(() => {
		document.addEventListener(
			"message",
			(event) => {
				// postMessage('connect', '*')
				let msg = event.data;
				msg = JSON.parse(msg);
				if (msg.location) {
					localStorage.setItem("coordsMob", JSON.stringify(msg.location));
				} else {
					localStorage.setItem("coordsMob", "false");
				}

				if (msg.token) {
					localStorage.setItem("pushToken", msg.token);
				}

				if (msg.back) {
					if (navigate.location.pathname !== "/order") {
						navigate(-1);
						window.ReactNativeWebView.postMessage("close");
					} else {
						window.ReactNativeWebView.postMessage("closeFull");
					}
				}
			},
			false,
		);
	}, [navigate]);

	// check order status from android push in local store
	useEffect(() => {
		const orderCheckInterval = setInterval(() => {
			const pushOrderStage = localStorage.getItem("pushOrderStage")
				? JSON.parse(localStorage.getItem("pushOrderStage"))
				: null;
			const orderId = localStorage.getItem("orderId") || null;

			if (pushOrderStage && orderId) {
				const code = { data: { status: pushOrderStage, orderId, source: "Android" } };
				updateOrderStatus(code);
			}
		}, 500);

		return () => {
			clearInterval(orderCheckInterval);
		};
	}, [updateOrderStatus]);

	useEffect(() => {
		// stop native app preloader
		if (!pagesCustomStopPreloader.includes(location.pathname)) {
			onContentLoaded();
		}
	}, [location.pathname]);

	const addSocket = window.location.href.includes("order");
	return (
		<div className="App app_light_theme">
			<ErrorComponent />
			<ErrorSocket />
			<ErrorToken />
			<SignalRComponent gps={false} coords={pos} />
			<Suspense fallback={<Loader />}>
				<Routes>
					{/* Lazy */}
					<Route
						path="/favorite"
						element={<ProtectedRoute Component={LazyFavoriteAddressPage} />}
					/>

					<Route exact path="/" element={<AuthenticationPage />} />
					<Route exact path="/auth" element={<AuthPage />} />
					<Route exact path="/auth-password" element={<AuthPasswordPage />} />
					<Route exact path="/registration" element={<RegistrationPage />} />
					<Route exact path="/code" element={<SendCodePage />} />
					<Route exact path="/forgot" element={<ForgotPage />} />
					<Route exact path="/rules" element={<ApkRulesPage />} />
					<Route exact path="/policy" element={<ApkPolicyPage />} />
					<Route path="/profile" element={<ProtectedRoute Component={ProfilePage} />} />
					<Route path="/edit-profile" element={<ProtectedRoute Component={EditProfilePage} />} />

					<Route path="/order-route" element={<ProtectedRoute Component={OrderRoute} />} />
					<Route path="/order-comment" element={<ProtectedRoute Component={OrderComment} />} />
					<Route path="/order-payment" element={<ProtectedRoute Component={OrderPaymentPage} />} />
					<Route path="/settings" element={<ProtectedRoute Component={SettingsPage} />} />
					<Route path="/edit-password" element={<ProtectedRoute Component={EditPassword} />} />
					<Route
						path="/order-preferences"
						element={<ProtectedRoute Component={OrderPreferences} />}
					/>
					<Route path="/history" element={<ProtectedRoute Component={HistoryPage} />} />
					<Route path="/payment-info" element={<ProtectedRoute Component={PaymentInfoPage} />} />
					<Route
						path="/delete-account"
						element={<ProtectedRoute Component={DeleteAccountPage} />}
					/>
					<Route path="/support" element={<ProtectedRoute Component={QuestionsPage} />} />
					<Route path="/order" element={<ProtectedRoute Component={OrderPage} />} />
					<Route path="/order-done" element={<ProtectedRoute Component={OrderDonePage} />} />
					<Route path="/help" element={<ProtectedRoute Component={HelpListPage} />} />
					<Route path="/quest-acc" element={<ProtectedRoute Component={QuestionsAccountPage} />} />
					<Route path="/news" element={<ProtectedRoute Component={NewsPage} />} />
					<Route path="/chats" element={<ProtectedRoute Component={ChatHistoryPage} />} />
					<Route path="/invoices" element={<ProtectedRoute Component={InvoicesPage} />} />
					<Route path="*" element={<Navigate to="/" />} />
				</Routes>
			</Suspense>
			<ChangeMapSize />
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		pos: state.order.pos,
		userData: state.profile.userData,
		error: state.errorSocket.error,
		errorDataSocket: state.errorSocket.errorDataSocket,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		// dispatching plain actions
		setTheme: (value) => dispatch(setTheme(value)),
		errorFunctionClose: () => dispatch(errorFunctionClose()),
		updateOrderStatus: (data) => dispatch(updateOrderStatus(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
