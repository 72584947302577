import React, { memo } from 'react'
import { t } from 'i18next'
import { Button } from '../../../shared/ui/Button/button'
import { CARMA_VERSION } from '../../../config-in-git'
import cls from './menuFooter.module.scss'
import FormBlockInfo from 'src/components/ProfileForm/FormBlockInfo'
import { CheckCircleIcon, AstericsIcon, CrownIcon } from '../../../shared/assets/icons'

const MenuFooter = memo((props) => {
  const { userData, registrationCountryInfo } = props

  const appType = localStorage.getItem('appType')

  const linkToApp =
    appType === 'android'
      ? 'https://play.google.com/store/apps/details?id=com.carmataxi.driver'
      : 'https://apps.apple.com/ua/app/carma-driver/id6450720893'

  return (
    <div className={cls.footer}>
      <div className={cls.footer_info}>
        <FormBlockInfo
          className={cls.blockInfo}
          icon={CheckCircleIcon}
          title={t('trips')}
          value={userData.numberOfOrders}
          additionalStyle={true}
        />
        <FormBlockInfo
          className={cls.blockInfo}
          icon={AstericsIcon}
          title={t('estimate')}
          value={userData.rating}
          additionalStyle={true}
        />
        <FormBlockInfo
          className={cls.blockInfo}
          icon={CrownIcon}
          title={t('bonusy')}
          additionalStyle={true}
          value={userData.bonuses}
        />
      </div>
      <Button fullWidth className={cls.btn}>
        <a href={linkToApp}>Carma.Driver</a>
      </Button>
      <p className={cls.version}>{`${t('app_version')} ${CARMA_VERSION}`} </p>
    </div>
  )
})

export default MenuFooter
