import React, { memo, useCallback, useEffect, useState } from "react";
import { useNavigate, Navigate, Link } from "react-router-dom";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { t } from "i18next";
import { getPhoneLogin, resetLoginData, setCountryPhoneCode } from "./auth.action";
import { getUserCoords, getUserLocation, resetUserData } from "../Registration/registration.action";
import { Input } from "../../shared/ui/Input/Input";
import { CustomSwitch } from "../../shared/ui/Switch/switch";
import { Button, ButtonVariant } from "../../shared/ui/Button/button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Loader from "../../shared/ui/Loader/Loader";
import cls from "./Auth.module.scss";
import CountrySelector from "../CountrySelector";

const chkPhoneNumber = new RegExp(/^[0-9]{0,9}$/);

const Auth = memo((props) => {
	const {
		getPhoneLogin,
		loginPhoneCheck,
		getUserLocation,
		loginError,
		rpcLoaded,
		loginLoaded,
		registrationCountryInfo,
		getUserCoords,
		countryCode,
		registrationCoordsError,
		registrationRpcError,
		resetUserData,
		setCountryPhoneCode,
		resetLoginData
	} = props;

	const navigate = useNavigate();
	const [phone, setPhone] = useState("");
	const [switchState, setSwitchState] = useState(false);

	useEffect(() => {
		const fetchUserCoords = async () => {
			await getUserLocation(); //TODO
			await getUserCoords(false);
		};

		fetchUserCoords();

		return () => resetUserData();
	}, [getUserCoords, getUserLocation, resetUserData]);

	useEffect(() => {
		if (loginPhoneCheck && phone) {
			navigate("/auth-password");
		}
	}, [navigate, loginPhoneCheck, phone]);
	
	useEffect(() => {
		if (!phone.trim()) {
			resetLoginData()
		}
	}, [phone, resetLoginData]);

	const onChangePhone = useCallback((event) => {
		const inputValue = event.target.value.slice(0, 9);

		if (chkPhoneNumber.test(inputValue)) {
			setPhone(inputValue);
		}
	}, []);

	const onChangeSwitch = useCallback(() => {
		setSwitchState(!switchState);
	}, [switchState]);

	const isCorrect = useCallback(() => {
		if (phone.length === 9 && switchState) {
			return false;
		}

		return true;
	}, [phone.length, switchState]);

	const onSubmitCheck = useCallback(async () => {
		const correctPhone = countryCode + phone;
		await getPhoneLogin({ phone: correctPhone });
	}, [countryCode, getPhoneLogin, phone]);

	//!TODO
	if (loginLoaded || rpcLoaded) {
		return <Loader />;
	}

	if (registrationCoordsError || registrationRpcError) {
		return <Navigate to="/" />;
	}

	return (
		<div className={cls.LoginWrapper}>
			<h1 className={cls.title}>{t("enter_phone")}</h1>
			<div className={cls.inputPhone}>
				<CountrySelector
					countryInfo={registrationCountryInfo}
					setCountryPhoneCode={setCountryPhoneCode}
					error={loginError}
					errorText={t("acc_not_found")}
					countryCode={countryCode}
					placeholder={t("enter_phone")}
					type="number"
					maxLength="9"
					value={phone}
					onChange={onChangePhone}
				/>
			</div>
			<div className={cls.terms}>
				<p>
					<Trans
						i18nKey="agree_terms"
						components={{
							Link1: (
								<Link className={cls.link} to="/rules">
									link
								</Link>
							),
							Link2: (
								<Link className={cls.link} to="/policy">
									link
								</Link>
							),
						}}
					/>
				</p>
				<FormControlLabel
					checked={switchState}
					sx={{ mr: 0 }}
					onChange={onChangeSwitch}
					control={<CustomSwitch />}
				/>
			</div>
			<div className={cls.continueBtn}>
				<Button
					variant={ButtonVariant.background}
					fullWidth
					disabled={isCorrect()}
					onClick={onSubmitCheck}
				>
					{t("continue")}
				</Button>
			</div>
			<div className={cls.registrationLink}>
				<h3>{t("have_not_account")}</h3>
				<Link className={cls.link} to="/registration">
					{t("sign_up")}
				</Link>
			</div>
		</div>
	);
});

const mapStateToProps = (state) => {
	return {
		loginLoaded: state.auth.loginLoaded,
		loginPhoneCheck: state.auth.loginPhoneCheck,
		loginStatus: state.auth.loginStatus,
		loginError: state.auth.loginError,
		countryCode: state.auth.countryCode,
		rpcLoaded: state.registration.rpcLoaded,
		registrationCountryInfo: state.registration.registrationCountryInfo,
		registrationCoordsError: state.registration.registrationCoordsError,
		registrationRpcError: state.registration.registrationRpcError,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getPhoneLogin: (loginInfo) => dispatch(getPhoneLogin(loginInfo)),
		getUserCoords: (requireCoordinates) => dispatch(getUserCoords(requireCoordinates)),
		getUserLocation: () => dispatch(getUserLocation()),
		resetUserData: () => dispatch(resetUserData()),
		setCountryPhoneCode: (countryCode) => dispatch(setCountryPhoneCode(countryCode)),
		resetLoginData: () => dispatch(resetLoginData()),
	};
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Auth));
