import React, { memo } from 'react'
import Switch from '@mui/material/Switch'
import { styled } from '@mui/material/styles'

export const CustomSwitch = memo(
  styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(
    ({ theme, customTheme }) => ({
      width: 45,
      padding: 0,
      height: 29,
      '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 3,
        transitionDuration: '300ms',
        '&.Mui-checked': {
          transform: 'translateX(16px)',
          color: customTheme !== true ? '#ffffff' : '#1f2d3e', // Цвет кружка в состоянии checked
          '& + .MuiSwitch-track': {
            // Стили для фона переключателя в общем состоянии
            opacity: 1,
            border: 0,
          },
        },
      },
      '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
        color: customTheme !== true ? '#ffffff' : '#1f2d3e', // Общие стили для кружка переключателя в выключенном сосятонии.
      },
      '& .MuiSwitch-track': {
        borderRadius: '25px',
        backgroundColor: customTheme !== true ? '#9E9E9E' : '#657180', // Стили для фона переключателя в общем состоянии
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
          duration: 500,
        }),
      },
    })
  )
)
