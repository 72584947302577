import { parseLocalStorageItem } from "src/shared/helpers/parseLocalStorageItem";
import { axiosInstance } from "src/axios.config";
import { api, apiRPCSecond } from "../../config";
import { changeCountryPlaceOrderRoute } from "../OrderRoute/order.route.action";
import { t } from "i18next";
import { getPointsCountryData } from "src/pages/OrderPage/api/getPointsCountryData";

export const PUT_USERDATA_PENDING = "PUT_USERDATA_PENDING";
export const PUT_USERDATA_SUCCESS = "PUT_USERDATA_SUCCESS";
export const PUT_USERDATA_ERROR = "PUT_USERDATA_ERROR";
export const RESET_USERDATA = "RESET_USERDATA";

export const GET_USER_COORDS_SUCCESS = "GET_USER_COORDS_SUCCESS";
export const GET_USER_COORDS_ERROR = "GET_USER_COORDS_ERROR";

export const POST_PRC_INFO_SUCCESS = "POST_PRC_INFO_SUCCESS";
export const POST_PRC_INFO_ERROR = "POST_PRC_INFO_ERROR";

//! TODO: Temporary solution for requesting geolocation access permissions
export const getUserLocation = () => {
	return async () => {
		if (navigator.geolocation) {
			try {
				const position = await new Promise((resolve, reject) => {
					navigator.geolocation.getCurrentPosition(resolve, reject);
				});

				return position;
			} catch (error) {
				console.log("Error getting user location:", error);
			}
		} else {
			console.log("Location access is denied");
		}
	};
};

export const getUserCoords = (requireCoordinates = true, isAuthentication = true) => {
	return async (dispatch) => {
		dispatch({ type: PUT_USERDATA_PENDING });

		try {
			const coordsClient = parseLocalStorageItem("coords");
			const coords = coordsClient ? `${coordsClient.latitude} ${coordsClient.longitude}` : null;

			const resultRpc = await dispatch(
				sendCoordsToRpc(coords, requireCoordinates, isAuthentication),
			);

			if (!resultRpc && requireCoordinates && !coordsClient?.latitude && !coordsClient?.longitude) {
				dispatch({ type: GET_USER_COORDS_ERROR });
			} else {
				dispatch({ type: GET_USER_COORDS_SUCCESS, payload: coords });
			}
		} catch (error) {
			dispatch({
				type: POST_PRC_INFO_ERROR,
				payload: t("there_was_unexpected_error_try_again"),
			});
		}
	};
};
export const sendCoordsToRpc = (
	coordsData,
	hasError = true,
	isAuthentication = false,
	shouldUpdateData = true,
) => {
	return async (dispatch) => {
		dispatch({ type: PUT_USERDATA_PENDING });

		try {
			const currentLanguage = (localStorage.getItem("language_name") || "").toLowerCase();
			const response = await getPointsCountryData({ coordsData, isAuthentication })

			if (response.data?.country) {
				localStorage.setItem("country_code", response.data.country.country_code);
			}

			if (!response.data.success && hasError) {
				const errorMessage = response.data.error[currentLanguage];
				localStorage.setItem("country_code", null);
				dispatch({ type: POST_PRC_INFO_ERROR, payload: errorMessage });
				return false;
			}

			if (shouldUpdateData) {
				dispatch({ type: POST_PRC_INFO_SUCCESS, payload: response.data.country });
				localStorage.setItem("timezone", response.data.country.timezone);
			}

			dispatch(changeCountryPlaceOrderRoute(response.data.country));

			return true;
		} catch (error) {
			const errorStatus = error.response?.status;
			console.log("error in sendCoordsToRpc: ", errorStatus);

			localStorage.setItem("country_code", null);

			if (hasError && errorStatus !== 422) {
				dispatch({
					type: POST_PRC_INFO_ERROR,
					payload: t("there_was_unexpected_error_try_again"),
				});

				// infinity loop
				// dispatch({ type: POST_PRC_INFO_SUCCESS, payload: {} });

				return false;
			}
		}
	};
};

export const registration = (userData) => {
	localStorage.setItem("registrationData", JSON.stringify(userData));
	return (dispatch) => {
		dispatch({
			type: PUT_USERDATA_PENDING,
		});
		axiosInstance
			.post(api + "/rider/sign-up", {
				branchId: 1,
				phone: userData.phone,
				email: userData.email,
				country: userData.country,
				currency: userData.currency,
				language: userData.language,
				firstName: userData.name,
				lastName: userData.surname,
				password: userData.password,
			})
			.then((response) => {
				console.log("Action* - регистрация УСПЕХ: ", response);
				if (response.status === 200 || response.status === 204) {
					dispatch({
						type: PUT_USERDATA_SUCCESS,
						payload: true,
					});
				} else {
					dispatch({
						type: PUT_USERDATA_ERROR,
						payload: false,
					});
				}
			})
			.catch((error) => {
				console.log("Action* - регистрация ОШИБКА: ", error);
				dispatch({
					type: PUT_USERDATA_ERROR,
					payload: error,
				});
			});
	};
};

export const resetUserData = () => {
	return (dispatch) => {
		dispatch({
			type: RESET_USERDATA,
		});
	};
};

export default registration;
