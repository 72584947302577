import React, { useCallback, useEffect, useMemo, useState } from "react";
import { t } from "i18next";
import { ReactComponent as FirstAdressPoint } from "../../shared/assets/icons/FirstAdressPoint.svg";
import { ReactComponent as FirstAdressPointDark } from "../../shared/assets/icons/FirstAdressPointDark.svg";
import { ReactComponent as CommentIcon } from "../../shared/assets/icons/CommentIcon.svg";
import { ReactComponent as WriteComment } from "../../shared/assets/icons/WriteComment.svg";
import { ReactComponent as ArrowBack } from "../../shared/assets/icons/ArrowBack.svg";
import cls from "./styles.module.scss";
import { connect } from "react-redux";
import {
	changeDopServicesOrderRoute,
	changeCommentOrderRoute,
	selectedCommentIdOrderRoute,
	paymentTypes,
	changePaymentTypeOrderRoute,
	changePriceOrderRoute,
	selectedTariffCarOrderRoute,
	clearOrderRoute,
	changeTravelRotesOrderRoute,
	setOrderCurrentPrice,
	paymentsCart,
	updateTariffCar,
} from "../OrderRoute/order.route.action";
import { postBonus, postOrder, resetErrorOrder, clearUsedBonuses } from "../MapJs/order.action";
import { getGoogleTravel } from "../OrderRoute/helperCalculate";
import { getOrderCost, getCarClassTimeInfo } from "../MapJs/order.action";
import { ReactComponent as Check } from "../../images/check.svg";
import { getValuteSymbol } from "../CurrencyDefinition/getValuteSymbol";
import { mapProvider } from "src/shared/variables/map-provider";
import { fetchDirection } from "../AddressComponents/helpers/fetchDirection";
import { Button, ButtonVariant } from "src/shared/ui/Button/button";
import { textFormator } from "../OrderRoute/AutocompleteOrderHandler";
import { getOpenFromMenu } from "../ProfileForm/profile.action";
import { showModalAlert } from "../Modals/store/modal.action";
import Modal from "src/shared/ui/Modal/Modal";
import AlertModal from "../Modals/ui/AlertModal/AlertModal";

let isFirst = true;

const getOrderInfoData = async ({ currentMapProvider, routes, sum, country, commonConfig }) => {
	let dataForMap = null;
	let orderInfoData = null;

	switch (currentMapProvider) {
		// request data for google maps
		case mapProvider.google:
			dataForMap = routes.map((e) => e.adress);
			const response = await getGoogleTravel(dataForMap);
			orderInfoData = {
				directionResponse: {
					geocoded_waypoints: response.geocoded_waypoints,
					routes: response.routes,
					status: "OK",
				},
				orderPreferencesSum: sum,
				originCountryCode: country,
				mapProvider: commonConfig.mapProvider,
			};
			return orderInfoData;
		case mapProvider.osmCarma:
			dataForMap = routes.map((point) => [point.lon, point.lat]);
			const directionResponse = await fetchDirection(dataForMap);

			orderInfoData = {
				directionResponse,
				orderPreferencesSum: sum,
				originCountryCode: country || "UA",
				mapProvider: commonConfig.mapProvider,
			};
			return orderInfoData;
		default:
			return null;
	}
};

function MapPreferences({
	preferences,
	dopServices,
	routes,
	getOrderCost,
	changeDopServicesOrderRoute,
	symbol,
	commentMessage,
	country,
	getCarClassTimeInfo,
	nameComponents,
	openAnotherComponentBottomMenu,
	editPrice,
	postBonus,
	postOrder,
	odrerRoute,
	userData,
	paymentType,
	changePriceOrderRoute,
	tariffCar,
	changePaymentTypeOrderRoute,
	distance,
	isOpenModalAlert,
	showModalAlert,
	orderError,
	resetErrorOrder,
	checkBonus,
	updateTariffCar,
	usedBonuses,
	clearUsedBonuses,
	estimatedPrice,
	price,
	setPrice,
}) {
	const commonConfig = useMemo(() => JSON.parse(localStorage.getItem("commonConfig")), []);
	const [titleAlert, setTitleAlert] = useState("");
	const [disableCreateOrder, setDisabledCreateOrder] = useState(false);

	// get current tariff by preferences
	const recalculatePrice = async () => {
		const sum = dopServices.reduce((a, e) => a + e.price, 0);

		const orderInfoData = await getOrderInfoData({
			currentMapProvider: commonConfig.mapProvider,
			routes,
			sum,
			country,
			commonConfig,
		});

		const dataOrder = await getOrderCost(orderInfoData);
		const currentTariff = dataOrder.data.find((e) => e.rate === tariffCar.rate);
		if (currentTariff) {
			updateTariffCar(currentTariff);
		}
		getCarArrivalTime(dopServices);

		return { currentTariff };
	};

	const back = async () => {
		await recalculatePrice();
		openAnotherComponentBottomMenu(nameComponents.CAR_PRICE);
	};

	const addComment = () => {
		openAnotherComponentBottomMenu(nameComponents.ORDER_COMMENT);
	};

	const getCurrentPaymentId = useCallback(() => {
		const isPaymentСard = paymentType.type === paymentTypes.CARD;
		let paymentId;

		if (isPaymentСard && checkBonus) {
			paymentId = 4;
		} else if (!isPaymentСard && checkBonus) {
			paymentId = 2;
		} else if (isPaymentСard) {
			paymentId = 1;
		} else {
			paymentId = 0;
		}

		return paymentId;
	}, [checkBonus, paymentType.type]);

	const getClientInfo = () => {
		const { id, firstName, lastName, phone, rating, bonuses, photo } = userData;
		const fullname = `${firstName ? firstName : " "} ${lastName ? lastName : " "}`;

		return {
			clientId: id,
			clientFullname: fullname,
			clientFirstName: firstName || "",
			clientLastName: lastName || "",
			clientPhoto: photo,
			clientPhone: phone,
			clientRating: rating,
			quoteBonus: bonuses || 0,
		};
	};

	const formatDestinationPoints = () => {
		return routes.map((route) => {
			const { name, id, lat, lon, ...otherProps } = route;
			const { secondaryText, mainText } = textFormator(name);
			return {
				pointId: id,
				name,
				secondaryText,
				mainText,
				lat,
				lon,
				...otherProps,
			};
		});
	};

	const generateOrderPaymentInfo = () => {
		const orderPaymentInfo = {
			paymentId: getCurrentPaymentId(),
		};

		localStorage.setItem("orderPaymentInfo", JSON.stringify(orderPaymentInfo));
	};

	const getCardId = useCallback(() => {
		const selectedPaymentId = localStorage.getItem("selectedPaymentId");
		const isPaymentCard = paymentType.type === paymentTypes.CARD;
		const cardId = isPaymentCard ? selectedPaymentId : null;

		return cardId;
	}, [paymentType.type]);

	const getOrderData = async () => {
		const clientInfo = await getClientInfo();
		const { currentTariff } = await recalculatePrice();
		const { estimatedPrice, ...otherCurrentTariffProps } = currentTariff;

		const destinationPoints = await formatDestinationPoints();

		const discountAndCompensationType = JSON.parse(
			localStorage.getItem("discountAndCompensationType"),
		);

		const priceRised = editPrice > estimatedPrice ? editPrice - estimatedPrice : 0;

		const countryValue = odrerRoute.infoCountry.country_code || "UA";
		const currencyValue = odrerRoute.infoCountry.currency_code || "UAH";
		generateOrderPaymentInfo();
		const duration = localStorage.getItem("durationTrip");

		return {
			...clientInfo,
			cityId: "72d01375-6a0b-4643-9e0f-68d349ea739b", // TODO: Change or remove city ID
			destinationPoints,
			priceEstimated: estimatedPrice,
			priceRised,
			orderPreferences: dopServices.map((e) => e.id),
			comment: commentMessage,
			distance,
			duration,
			paymentTypeId: getCurrentPaymentId(),
			cardId: getCardId(),
			carClassId: tariffCar.rate,
			country: countryValue,
			currency: currencyValue,
			discount: tariffCar?.discount || 0,
			discountType: discountAndCompensationType?.discountType || 0,
			compensation: tariffCar?.compensation || 0,
			compensationType: discountAndCompensationType?.compensationType || 0,
			quoteBonus: Math.floor(usedBonuses) ?? 0,
			...otherCurrentTariffProps,
		};
	};

	// create order
	const orderCar = async () => {
		setDisabledCreateOrder(true);
		const orderData = await postBonus(getOrderData());

		console.log("orderData", orderData);

		const result = await postOrder(orderData);

		if (result) {
			return;
		}

		openAnotherComponentBottomMenu(nameComponents.SEARCH_CAR);
		changePriceOrderRoute(price);

		localStorage.removeItem("watingAdress");
		localStorage.setItem("odrerRoute", JSON.stringify(odrerRoute));
		localStorage.removeItem("discountAndCompensationType");
		localStorage.removeItem("durationTripTimer");
		localStorage.removeItem("startDuration");
		setDisabledCreateOrder(false);
	};

	useEffect(() => {
		const createBonusData = () => {
			return {
				cardId: getCardId(),
				paymentTypeId: getCurrentPaymentId(),
				quoteBonus: userData.bonuses ?? 0,
				priceEstimated: price,
				country: odrerRoute.infoCountry.country_code || "UA",
			};
		};

		const fetchBonusesToOrder = async () => {
			if (checkBonus && price) {
				const dataBonuses = createBonusData();
				await postBonus(dataBonuses);
			} else {
				clearUsedBonuses();
			}
		};

		fetchBonusesToOrder();
	}, [
		checkBonus,
		clearUsedBonuses,
		getCardId,
		getCurrentPaymentId,
		odrerRoute.infoCountry.country_code,
		postBonus,
		price,
		userData.bonuses,
	]);

	const getCarArrivalTime = useCallback(
		async (orderPreferences) => {
			const watingAdress = JSON.parse(localStorage.getItem("watingAdress"));
			const firstPoint = watingAdress.odrerRoute.routes[0];

			const infoObj = {
				destinationPoints: {
					lat: firstPoint.lat,
					lon: firstPoint.lon,
				},
				orderPreferences,
			};

			await getCarClassTimeInfo(infoObj);
		},
		[getCarClassTimeInfo],
	);

	const changeDopServices = async (servece) => {
		const dopServece = dopServices.find((e) => e.id === servece.id);
		let newServeses;
		if (dopServece) {
			newServeses = dopServices.filter((e) => e.id !== servece.id);
		} else {
			newServeses = [...dopServices, servece];
		}
		const sum = newServeses.reduce((a, e) => a + e.price, 0);

		changeDopServicesOrderRoute({
			dopServices: newServeses,
			sumDopServises: sum,
		});
	};

	useEffect(() => {
		setPrice(estimatedPrice);
	}, [estimatedPrice, setPrice]);

	useEffect(() => {
		if (editPrice !== null) {
			setPrice(editPrice);
		}

		if (isFirst) {
			isFirst = false;
			localStorage.getItem("watingAdress") === null &&
				changePaymentTypeOrderRoute({ ...paymentsCart, text: "cash" });
		}
	}, [changePaymentTypeOrderRoute, editPrice, setPrice]);

	useEffect(() => {
		if (orderError) {
			let title;
			switch (orderError) {
				case "insufficient_funds":
					title = t("not_enough_funds_on_your_card");
					break;
				case "card_is_not_available":
					title = t("card_is_not_supported_in_the_current_country");
					break;
				default:
					title = t("error_when_paying_by_card");
					break;
			}

			setTitleAlert(title);
			showModalAlert(true);
		}
	}, [orderError, showModalAlert]);

	const isDarkTheme = localStorage.getItem("appTheme") === "dark";

	return (
		<>
			<div className={cls.arrow_back} onClick={back}>
				<ArrowBack />
			</div>
			<Modal isOpen={isOpenModalAlert} onClose={resetErrorOrder} isCloseAction>
				<AlertModal title={titleAlert} />
			</Modal>
			<div className={cls.MapPreferencesWrapper}>
				<div className={cls.preferencesBlock}>
					<div className={cls.headerBlock}>
						<div className={cls.firstRoute}>
							{isDarkTheme ? <FirstAdressPointDark /> : <FirstAdressPoint />}
							<p>{routes.length > 0 && routes[0].name}</p>
						</div>
						<div className={cls.firstRoute} onClick={addComment}>
							{commentMessage ? (
								<>
									<WriteComment />
									<p>{commentMessage}</p>
								</>
							) : (
								<>
									<CommentIcon />
									<p>{t("clients_comment")}</p>
								</>
							)}
						</div>
					</div>
					<div className={cls.preferencesServices}>
						{preferences.map((serv, index) => {
							const active = dopServices.find((e) => e.id === serv.id);
							return (
								<button
									key={serv.id}
									className={`${cls.preferencesServicesBtn} ${active ? cls.active : ""}`}
									onClick={() => changeDopServices(serv)}
								>
									<div
										className={`${cls.preferencesServicesItem} ${
											index === preferences.length - 1 ? cls.last_item : ""
										}`}
									>
										<p>{t(serv.name)}</p>
										<div className={cls.preferencesPrice}>
											<div className={`${cls.sum} ${active ? cls.sumActive : ""}`}>
												<p>+{serv.price}</p>
												<p>{symbol}</p>
											</div>
											{active && (
												<div
													className={cls.check}
													onClick={(e) => {
														e.stopPropagation();
													}}
												>
													<Check />
												</div>
											)}
										</div>
									</div>
								</button>
							);
						})}
					</div>
					<div className={cls.confirmBlock}>
						<Button
							disabled={disableCreateOrder}
							variant={ButtonVariant.background}
							fullWidth
							onClick={orderCar}
						>
							{t("confirm")}
						</Button>
					</div>
				</div>
			</div>
		</>
	);
}

const mapStateToProps = (state) => {
	return {
		preferences: state.dictionary.preferences,
		dopServices: state.odrerRoute.dopServices,
		routes: state.odrerRoute.routes,
		commentMessage: state.odrerRoute.commentMessage,
		selectedCommentsId: state.odrerRoute.selectedCommentsId,
		country: state.odrerRoute.infoCountry.country_code,
		symbol: getValuteSymbol(state.odrerRoute.infoCountry.currency_code),
		tariffCar: state.odrerRoute.tariffCar,
		paymentType: state.odrerRoute.paymentType,
		travelRoutes: state.odrerRoute.travelRoutes,
		userData: state.profile.userData,
		userCardsList: state.ccard.userCardsList,
		sumDopServises: state.odrerRoute.sumDopServises,
		editPrice: state.odrerRoute.editPrice,
		carArrivalTimes: state.order.carArrivalTimes,
		registrationCountryInfo: state.registration.registrationCountryInfo,
		distance: state.odrerRoute.distance,
		orderError: state.order.orderError,
		isOpenModalAlert: state.modal.isOpenModalAlert,
		orderLoaded: state.order.orderLoaded,
		usedBonuses: state.order.usedBonuses,
	};
};

export default connect(mapStateToProps, {
	changeDopServicesOrderRoute,
	getOrderCost,
	changeCommentOrderRoute,
	selectedCommentIdOrderRoute,
	getCarClassTimeInfo,
	selectedTariffCarOrderRoute,
	changeTravelRotesOrderRoute,
	getOpenFromMenu,
	postBonus,
	postOrder,
	changePriceOrderRoute,
	changePaymentTypeOrderRoute,
	clearOrderRoute,
	setOrderCurrentPrice,
	showModalAlert,
	resetErrorOrder,
	updateTariffCar,
	clearUsedBonuses,
})(MapPreferences);
