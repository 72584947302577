import React, { memo, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { t } from 'i18next'
import { showModal, showModalAlert } from '../Modals/store/modal.action'
import { resetUserData } from '../Registration/registration.action'
import AlertModal from '../Modals/ui/AlertModal/AlertModal'
import { Button } from '../../shared/ui/Button/button'
import logoMap from '../../images/logo-map.png'
import logo from '../../images/logo.png'
import Modal from '../../shared/ui/Modal/Modal'
import cls from './authentication.module.scss'
import useLanguageManager from 'src/shared/hooks/useLanguageManager'
import { onContentLoaded } from 'src/shared/callbacks'

const Authentication = memo((props) => {
  const {
    isOpenModalAlert,
    registrationCoordsError,
    registrationRpcError,
    resetUserData,
    showModalAlert,
  } = props

  const [titleAlert, setTitleAlert] = useState('')
  const { language, getCurrentLanguage } = useLanguageManager()

  useEffect(() => {
    getCurrentLanguage()
  }, [getCurrentLanguage, language])

  useEffect(() => {
    if (registrationCoordsError || registrationRpcError) {
      showModalAlert(true)
      if (registrationRpcError) {
        return setTitleAlert(registrationRpcError)
      }

      if (registrationCoordsError) {
        return setTitleAlert(t('provide_access_geolocation_to_continue_registration'))
      }
    }
  }, [registrationCoordsError, registrationRpcError, showModalAlert])

  useEffect(() => {
		// disabled loader for native app
		onContentLoaded();
	});

  return (
    <div className={cls.AuthenticationWrapper}>
      <Modal isOpen={isOpenModalAlert} isCloseAction onClose={resetUserData}>
        <AlertModal title={titleAlert} />
      </Modal>
      <div className={cls.logoImage}>
        <div className={cls.logoMap}>
          <img src={logoMap} alt="Logo Map" />
        </div>
        <div>
          <img src={logo} alt="Logo Text" />
        </div>
      </div>
      <div className={cls.btnAuthentication}>
        <Link to="/auth" className={cls.link}>
          <Button fullWidth className={cls.btn}>
            {t('enter')}
          </Button>
        </Link>
        <Link to="/registration" className={cls.link}>
          <Button fullWidth className={cls.btn}>
            {t('sign_up')}
          </Button>
        </Link>
      </div>
    </div>
  )
})

const mapStateToProps = (state) => {
  return {
    isOpenModal: state.modal.isOpenModal,
    isOpenModalAlert: state.modal.isOpenModalAlert,
    registrationCoordsError: state.registration.registrationCoordsError,
    registrationRpcError: state.registration.registrationRpcError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showModal: (status) => dispatch(showModal(status)),
    showModalAlert: (status) => dispatch(showModalAlert(status)),
    resetUserData: () => dispatch(resetUserData()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Authentication)
