// Свагер урл + /docs/index.html
const url = process.env.REACT_APP_BACKEND_URL;

export const api = `${url}/identity`;
export const apiData = `${url}/client`;
export const apiOrder = `${url}/order`;
export const apiSignalR = `${url}/client/ws/smart-taxi-rider`;
export const apiCard = process.env.REACT_APP_PAYMENT_GATE;
export const apiRPC = process.env.REACT_APP_RPC;
export const apiRPCSecond = process.env.REACT_APP_RPC_SECOND;
export const apiImages = `${url}/file`;
export const apiGeocode = process.env.REACT_APP_GEOCODE + "/search";
export const apiLogSelectedAddress = process.env.REACT_APP_GEOCODE + "/log-selected-address";

export const apiReverseGeocode = process.env.REACT_APP_GEOCODE + "/reverse-geocode";
export const osmMapPolyline = process.env.REACT_APP_OSM_MAP_POLYLINE;

export const apiCards = `${url}/payment`;
export const paymentGate = `${url}/payment`;

export const zendeskKey = process.env.REACT_APP_ZENDESK_KEY;

// URL - V2
export const paymentGateV2 = `${url}/payment/v2`;
export const apiCardsV2 = `${url}/payment/v2`;

export const pendingTime = 60;
export const clientLocation = `${process.env.REACT_APP_BACKEND_URL}/client/profile/location`;
export const svcApi = `${process.env.REACT_APP_BACKEND_URL}/svc/info`;

// Дизайн приложения
// https://www.figma.com/file/xQaFCgPK9wWVdLPOfbJDo1/%D0%9B%D1%8E%D0%B1%D0%B8%D0%BC%D0%BE%D0%B5-%D1%82%D0%B0%D0%BA%D1%81%D0%B8?node-id=335%3A0

// Редмайн
// https://redmine.quadrobit.com/projects/taxi
// smart/home
