import React from 'react'

export const WordCarmaIcon = (props) => {
  const { width = 25, height = 26, fill = 'none', stroke = '#212121', className } = props

  return (
    <svg width={width} height={height} fill={fill} {...props}>
      <path
        fill="#000"
        d="M6.195 24.34a11.342 11.342 0 0 1-4.6-4.613A13.927 13.927 0 0 1 .002 13c-.035-2.33.53-4.628 1.638-6.658a11.83 11.83 0 0 1 4.64-4.648 13.995 13.995 0 0 1 6.98-1.69 14.51 14.51 0 0 1 5.903 1.12 10.19 10.19 0 0 1 4.02 3.002A8.832 8.832 0 0 1 25 8.307h-3.84a5.528 5.528 0 0 0-1.425-2.696 6.937 6.937 0 0 0-2.696-1.713 10.23 10.23 0 0 0-3.505-.582h-.563a9.475 9.475 0 0 0-4.977 1.272 8.503 8.503 0 0 0-3.233 3.473 11.37 11.37 0 0 0-1.126 4.94c0 1.715.385 3.406 1.126 4.94a8.497 8.497 0 0 0 3.233 3.47 9.476 9.476 0 0 0 4.977 1.27h.563c1.193.014 2.38-.176 3.513-.562a6.797 6.797 0 0 0 2.68-1.684 5.65 5.65 0 0 0 1.434-2.746H25a8.932 8.932 0 0 1-1.893 4.333 10.017 10.017 0 0 1-4.045 2.924 14.967 14.967 0 0 1-5.803 1.05 14.423 14.423 0 0 1-7.064-1.655Z"
      />
    </svg>
  )
}
